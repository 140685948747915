import React, {useEffect, useState} from 'react';

const Unsubscribe = () => {
  const [error, setError] = useState<any>(null);

  const browser = typeof window !== 'undefined';

  useEffect(() => {
    if (
      global &&
      global.window &&
      global.window.location &&
      global.window.location.search &&
      global.window.location.search.includes('error')
    ) {
      setError(true);
    } else if (
      global &&
      global.window &&
      global.window.location &&
      global.window.location.search &&
      global.window.location.search.includes('success')
    ) {
      setError(false);
    }
  }, []);

  return (
    browser && typeof error === 'boolean' && (
      <div className="content-wrapper">
        <p
          style={{
            color: '#C84D40',
            marginBottom: '30px',
            lineHeight: '46px',
            fontSize: '38px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {!error ? 'Opt Out Successful!' : 'Something went wrong.'}
        </p>
        {!error && (
          <p style={{color: '#2E3645', lineHeight: '23px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center'}}>
            You'll no longer receive the following type of email communication from this sender.
          </p>
        )}
      </div>
    )
  );
};

export default Unsubscribe;
